import BulmaCarousel from 'bulma-carousel'
import classNames from 'classnames'
import { graphql, StaticQuery, } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import React from 'react'


interface IHomeGalleryProps {
  autoplay?: boolean,
  delay?: number,
}

interface IHomeGalleryRenderProps {
  site: {
    siteMetadata: {
      title: string
      subtitle: string
      by: string
    }
  }
  index: {
    childDataJson: {
      carousel: {
        images: Array<{
          alt: string
          active: boolean
          url: {
            childImageSharp: {
              fluid: FluidObject
            }
          }
        }>
      }
    }
  }
}

const query = graphql`query HomeGalleryQuery {
  site {
    siteMetadata {
      title
      subtitle
      by
    }
  }
  index: file(name: {eq: "index"}) {
    childDataJson {
      carousel {
        images {
          alt
          active
          url {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 85) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
}`

export default class HomeGallery extends React.Component<IHomeGalleryProps, {}> {
  private static defaultProps: Partial<IHomeGalleryProps> = {
    autoplay: true,
    delay: 10000,
  }

  private readonly carouselRef: React.RefObject<HTMLDivElement>
  private carousel: object | undefined

  constructor(props: IHomeGalleryProps) {
    super(props)
    this.carouselRef = React.createRef<HTMLDivElement>()
  }

  componentDidMount() {
    const { autoplay, delay } = this.props
    if (this.carouselRef.current !== null) {
      this.carousel = new BulmaCarousel(this.carouselRef.current, {
        autoplay,
        delay,
      })
    }
  }

  render() {
    const render = (data: IHomeGalleryRenderProps) => {
      const {
        site: { siteMetadata: meta },
        index: { childDataJson: index },
      } = data
      return (
        <section className="HomeGallery hero is-fullheight-with-navbar has-carousel is-dark"
                 aria-label="Image carousel">
          <div className="hero-carousel carousel-animated carousel-animate-fade"
               ref={this.carouselRef}
               aria-hidden="true">
            <div className="carousel-container">
              {index.carousel.images.map((image, i) => (
                <div className={classNames('carousel-item', 'has-background',
                  { 'is-active': image.active })}
                     key={i}>
                  <Img fluid={image.url.childImageSharp.fluid}
                       alt={image.alt}
                       className="is-background"
                       style={{ height: '100%' }} />
                </div>
              ))}
            </div>
          </div>
          <div className="hero-body">
            <div className="container">
              <div className="title">
                <h1>
                  {meta.title}
                  <small className="is-sr-only">di {meta.by}</small>
                </h1>
              </div>
              <p className="subtitle">
                <span>
                  {meta.subtitle}
                </span>
              </p>
            </div>
          </div>
        </section>
      )
    }

    return (
      <StaticQuery query={query} render={render} />
    )
  }
}

import React from 'react'
// import DescriptionContainer from '../components/index/DescriptionContainer'
import HomeGallery from '../components/index/HomeGallery'
import Layout from '../components/layout'

export default class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <HomeGallery />
        {/*<DescriptionContainer />*/}
      </Layout>
    )
  }
}
